/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Madman": {
		width: 15,
		height: 15,
		viewBox: '0 0 15 15',
		data: `<path pid="0" d="M7.5 2C11.085 2 14 4.468 14 7.5c0 3.033-2.915 5.5-6.5 5.5S1 10.533 1 7.5C1 4.469 3.915 2 7.5 2zm-3.054 9.242c.94 0 1.76-.574 2.082-1.406a9.265 9.265 0 0 0 1.945 0 2.224 2.224 0 0 0 2.082 1.406c1.227 0 2.226-.975 2.226-2.172 0-.512-.226-1.112-.524-1.4a.366.366 0 0 0-.508-.008c-.245.23-1.606 1.382-4.249 1.382S3.497 7.89 3.25 7.662c-.133-.126-.368-.118-.505.008-.244.22-.526.888-.526 1.4 0 1.197.998 2.172 2.227 2.172zm3.858-.193l.002-.004c-.03.03-.287.29-.806.29-.534 0-.793-.275-.807-.291a.331.331 0 0 0-.51-.007.468.468 0 0 0-.008.595c.046.057.478.543 1.325.543.848 0 1.28-.486 1.326-.543a.47.47 0 0 0-.009-.595.33.33 0 0 0-.513.012zm-5.24-2.505c.125.1.284.212.48.33a.935.935 0 0 0 .903 1.14.926.926 0 0 0 .76-.428c.17.047.35.091.54.129a1.595 1.595 0 0 1-1.3.683c-.828 0-1.5-.596-1.5-1.328 0-.196.048-.385.116-.526zm6.73 1.042a.92.92 0 0 0 .76.428c.51 0 .921-.423.921-.944 0-.057-.006-.135-.017-.197.193-.117.354-.23.48-.33.065.142.116.337.116.527 0 .732-.672 1.327-1.5 1.327-.54 0-1.042-.279-1.304-.681.19-.039.37-.082.544-.13z" _fill="#fff"/>`
	}
});
