/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"heart": {
		width: 18,
		height: 18,
		viewBox: '0 0 18 18',
		data: `<path pid="0" d="M15.63 3.458a4.125 4.125 0 0 0-5.835 0L9 4.253l-.795-.795A4.126 4.126 0 1 0 2.37 9.293l.795.795L9 15.922l5.835-5.835.795-.795a4.125 4.125 0 0 0 0-5.835v0z" _stroke="#EF5C5C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>`
	}
});
