import { Store, set, get, del, clear, keys } from "idb-keyval";
import { highlightStore } from "@/main";
import Settings from "./settings";
import store from "../store";

const authStore = new Store("anichart-auth", "auth");

async function init() {
	const accessToken = await get("token", authStore);
	if (!accessToken) return false;
	store.commit("setAccessToken", accessToken);
	return store.dispatch("fetchUser");
}

async function login(accessToken) {
	await set("token", accessToken, authStore);
	return init();
}

async function logout() {
	await del("token", authStore);
	store.commit("setAccessToken", null);
	store.commit("setUser", {
		user: {},
		settings: {},
		highlights: {}
	});
}

async function syncClient() {
	Settings.setAll(store.state.auth.settings);
	await clear(highlightStore);
	const highlights = Object.entries(store.state.auth.highlights);
	highlights.forEach(highlight => {
		set(parseInt(highlight[0]), highlight[1], highlightStore);
	});
}

async function syncServer() {
	const settings = Settings.getAll();
	await store.dispatch("updateAuthSettings", settings);

	const highlights = [];
	const highlightKeys = await keys(highlightStore);
	await Promise.all(
		highlightKeys.map(async mediaId => {
			const highlight = await get(mediaId, highlightStore);
			highlights.push({
				mediaId,
				highlight
			});
		})
	);
	await store.dispatch("updateHighlights", {
		highlights
	});
}

export default {
	init,
	login,
	logout,
	syncClient,
	syncServer
};
