<template>
	<div class="trailer-wrap" :class="{ active }" @click.self="close" ref="modal">
		<div class="trailer modal" :style="styles">
			<iframe
				:src="videoSource"
				class="video"
				:class="{ loaded: videoLoaded }"
				@load="onVideoLoad"
				allow="autoplay"
				frameborder="0"
			/>
		</div>
	</div>
</template>

<script>
export default {
	props: ["trailer", "position"],
	data() {
		return {
			active: false,
			videoLoaded: false
		};
	},
	computed: {
		styles() {
			return {
				"background-image": `url("${this.trailer.thumbnail}")`,
				left: `${this.position.left}px`,
				top: `${this.position.top}px`
			};
		},
		videoSource() {
			if (this.trailer.site === "youtube") {
				return `https://www.youtube.com/embed/${this.trailer.id}?autoplay=1&autohide=1`;
			}
			return `https://www.dailymotion.com/embed/video/${this.trailer.id}?autoplay=1`;
		}
	},
	methods: {
		close() {
			this.videoLoaded = false;
			this.active = false;
			setTimeout(() => {
				this.$store.commit("setTrailer", {
					active: false
				});
			}, 400);
		},
		onVideoLoad() {
			this.videoLoaded = true;
		}
	},
	async mounted() {
		await this.$nextTick();
		setTimeout(() => {
			this.active = true;
		}, 100);
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.trailer-wrap {
	left: 0;
	position: fixed;
	top: 0;
	transition: background 200ms ease-in-out;
	height: 100vh;
	width: 100vw;
	z-index: 999;
}

.trailer {
	&.modal {
		backface-visibility: hidden;
		border-radius: 6px;
		position: relative;
		transition: left 300ms ease-in-out, top 300ms ease-in-out, width 300ms ease-in-out,
			height 300ms ease-in-out;
	}

	.video {
		height: 100%;
		opacity: 0;
		transition: opacity 600ms;
		width: 100%;
		z-index: 960;

		&.loaded {
			opacity: 1;
		}
	}
}

.active {
	&.trailer-wrap {
		background: rgba(0, 0, 0, 0.8);
	}

	.trailer {
		height: calc(80vw * 0.5625);
		left: 10vw !important;
		top: calc(50vh - calc(80vw * 0.28125)) !important;
		width: 80vw;
	}
}
</style>
