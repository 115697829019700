import worker from "worker-loader!./api.worker.js";
import nanoid from "nanoid";

const APIWorker = worker();
APIWorker.addEventListener("message", ({ data }) => {
	const event = new MessageEvent(`worker-${data.id}`, { data });
	window.dispatchEvent(event);
});

async function* generatorProxy(id) {
	const workerYield = () =>
		new Promise(resolve => {
			window.addEventListener(
				`worker-${id}`,
				({ data }) => {
					yields.push(workerYield());
					resolve(data);
				},
				{ once: true }
			);
		});
	const yields = [workerYield()];

	for (let i = 0; i < yields.length; i++) {
		const data = await yields[i];
		if (data.error) {
			throw data.error;
		} else if (data.done) {
			return;
		}

		yield data.data;
	}
}

function callWorkerGenerator(func, args) {
	const id = nanoid();
	const generator = generatorProxy(id);
	APIWorker.postMessage({
		id,
		args,
		func
	});

	return generator;
}

function callWorker(func, args) {
	const id = nanoid();
	APIWorker.postMessage({
		id,
		args,
		func
	});

	return new Promise(resolve => {
		window.addEventListener(`worker-${id}`, ({ data }) => resolve(data), { once: true });
	});
}

export default {
	fetchBySeason: (...args) => callWorkerGenerator("fetchBySeason", ...args),
	fetchTBA: (...args) => callWorkerGenerator("fetchTBA", ...args),
	fetchAiring: (...args) => callWorkerGenerator("fetchAiring", ...args),
	fetchUser: (...args) => callWorker("fetchUser", ...args),
	updateSettings: (...args) => callWorker("updateSettings", ...args),
	updateHighlights: (...args) => callWorker("updateHighlights", ...args),
	updateListEntry: (...args) => callWorker("updateListEntry", ...args)
};
