/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Fall": {
		width: 23,
		height: 21,
		viewBox: '0 0 23 21',
		data: `<path pid="0" d="M21.808.398c-.224-.513-.863-.533-1.13-.05-1.238 2.211-3.454 3.59-5.986 3.59h-3.194c-4.233 0-7.667 3.527-7.667 7.874 0 .288.032.562.06.841 2.548-1.874 6.225-3.466 11.44-3.466a.65.65 0 0 1 .639.657.65.65 0 0 1-.639.656C5.293 10.5 1.036 16.82.094 19.195c-.264.669.048 1.432.698 1.707.655.278 1.398-.046 1.67-.71.06-.148.834-1.965 2.87-3.716 1.294 1.8 3.754 3.52 6.984 3.166 6.27-.467 10.682-6.242 10.682-13.313 0-2.06-.431-4.192-1.19-5.931z" _fill="#fff" fill-opacity=".85"/>`
	}
});
