<template>
	<header class="nav-placement">
		<div class="nav-wrap">
			<div class="nav seasons">
				<template v-for="(year, season) in nextSeasons">
					<router-link
						class="season link"
						:to="{ name: 'season', params: { season, year } }"
						:key="season"
					>
						<icon class="icon" :name="season" width="20" color="currentColor" :fill="true" />
						<div class="season-name label">{{ season }}</div>
					</router-link>
				</template>
			</div>

			<div class="nav other" :class="{ inactive: !navExpanded }">
				<router-link class="link" to="/archive">
					<icon class="icon" name="archive" width="20" color="currentColor" aria-label="Archive" />
					<div class="label">Archive</div>
				</router-link>
				<router-link class="link" to="/tba">
					<icon
						class="icon"
						name="tba"
						width="20"
						color="currentColor"
						aria-label="To be announced"
					/>
					<div class="label">TBA</div>
				</router-link>
				<router-link class="link" to="/airing">
					<icon class="icon" name="airing" width="20" color="currentColor" aria-label="Airing" />
					<div class="label">Airing</div>
				</router-link>
				<router-link class="link" to="/settings">
					<icon
						class="icon"
						name="settings"
						width="20"
						stroke-width="2px"
						color="currentColor"
						aria-label="Settings"
					/>
					<div class="label">Settings</div>
				</router-link>
			</div>
		</div>

		<div class="btn nav" @click="toggleExpandedMenu">
			<icon class="icon menu" name="menu" width="30" />
		</div>
	</header>
</template>

<script>
import { nextSeasons } from "@/util";

export default {
	data() {
		return {
			navExpanded: false
		};
	},
	computed: {
		nextSeasons() {
			return nextSeasons();
		}
	},
	methods: {
		toggleExpandedMenu() {
			this.navExpanded = !this.navExpanded;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.nav-placement {
	bottom: 0;
	background: linear-gradient(to top, color(background, 1), 70%, color(background, 0));
	display: grid;
	grid-gap: 20px;
	grid-template-columns: auto 55px;
	left: 0;
	padding: 18px;
	position: fixed;
	width: 100%;
	z-index: 100;
	padding-top: 30px;

	@media (max-width: $size-mobile) {
		grid-gap: 10px;
		padding: 10px;
	}
}

.nav-wrap {
	position: relative;
}

.nav {
	align-content: center;
	background: #2b2d42;
	border-radius: 5px;
	box-shadow: 0 4px 6px shadow(shadow, 0.05), 0 5px 20px shadow(shadow, 0.08);
	color: #fff;
	display: flex;
	height: 55px;
	justify-content: space-evenly;
}

.seasons,
.other {
	color: hsl(236, 55%, 86%);
	transition: opacity 200ms ease;
	position: absolute;
	width: 100%;
}

.link {
	align-items: center;
	display: inline-flex;
	flex-flow: column;
	height: 100%;
	justify-content: center;
	text-align: center;
	margin-top: 1px;

	&.router-link-active {
		color: #fff;
		.label {
			color: #fff;
		}
	}

	.icon {
		display: inline-block;
		margin-bottom: 7px;
		height: 20px;
	}
}

.label {
	color: hsla(236, 55%, 86%, 0.8);
	font-size: 0.9rem;
	font-weight: 600;
}

.btn {
	color: hsl(237, 70%, 92%);
}

.inactive {
	opacity: 0;
	pointer-events: none;
}
</style>
