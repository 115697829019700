import _ from "lodash";
import store from "@/store";

export default class CacheInvalidator {
	constructor(stateKey) {
		const cache = store.getters.chart(stateKey);
		this.cacheExists = cache.length !== 0;
		if (!this.cacheExists) return;

		this.stateKey = stateKey;
		this.currentCache = [...cache];
		this.newCache = [];
	}

	addNewCache(cache) {
		if (!this.cacheExists) return;
		this.newCache = this.newCache.concat(cache);
	}

	deleteInvalidCache() {
		if (!this.cacheExists) return;
		const invalidCache = _.difference(this.currentCache, this.newCache);
		if (invalidCache.length === 0) return;

		store.commit("removeInvalidChartEntries", {
			chartKey: this.stateKey,
			entries: invalidCache
		});
	}
}
