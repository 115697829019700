import Vue from "vue";
import Router from "vue-router";
import { getSeason, capitalize } from "@/util";

const SeasonView = () => import("@/views/Season" /* webpackChunkName: "season-view" */);
const TbaView = () => import("@/views/Tba" /* webpackChunkName: "tba-view" */);
const AiringView = () => import("@/views/Airing" /* webpackChunkName: "airing-view" */);
const ArchiveView = () => import("@/views/Archive" /* webpackChunkName: "archive-view" */);
const SettingsView = () => import("@/views/Settings" /* webpackChunkName: "settings-view" */);
const AuthView = () => import("@/views/Auth" /* webpackChunkName: "settings-view" */);
import NotFoundView from "@/views/NotFound";

Vue.use(Router);

const handleSeasonProps = route => ({
	season: String(route.params.season),
	year: Number(route.params.year)
});

const currentSeasonParams = () => {
	const { year, season } = getSeason(1);
	return {
		year,
		season: capitalize(season)
	};
};

const routes = [
	{
		path: "/",
		redirect: () => {
			return {
				name: "season",
				params: currentSeasonParams()
			};
		}
	},
	{
		path: "/:season-:year",
		name: "season",
		component: SeasonView,
		props: handleSeasonProps,
		beforeEnter: (to, from, next) => {
			const seasons = ["winter", "spring", "summer", "fall"];
			const season = String(to.params.season);
			const year = Number(to.params.year);

			if (!seasons.includes(season.toLowerCase()) || !year || String(year).length !== 4) {
				next("/404");
			}

			next();
		}
	},
	{
		path: "/tba",
		name: "tba",
		component: TbaView
	},
	{
		path: "/airing",
		name: "airing",
		component: AiringView
	},
	{
		path: "/archive",
		name: "archive",
		component: ArchiveView
	},
	{
		path: "/settings",
		component: SettingsView
	},
	{
		path: "/auth",
		component: AuthView
	},
	{
		path: "/404",
		component: NotFoundView
	},
	{
		path: "*",
		redirect: () => ({
			path: "/404"
		})
	}
];

const router = new Router({
	mode: "history",
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) return savedPosition;
		return { x: 0, y: 0 };
	},
	routes
});

export { routes, router };
