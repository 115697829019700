/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Spring": {
		width: 22,
		height: 20,
		viewBox: '0 0 22 20',
		data: `<path pid="0" d="M2.75 3.125H0C0 8.44 4.31 12.75 9.625 12.75v6.188c0 .378.31.687.688.687h1.374a.69.69 0 0 0 .688-.688V12.75c0-5.315-4.31-9.625-9.625-9.625zm16.5-2.75c-3.618 0-6.763 1.998-8.409 4.95a11.017 11.017 0 0 1 2.535 4.623C18.22 9.446 22 5.355 22 .375h-2.75z" _fill="#fff" fill-opacity=".85"/>`
	}
});
