/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Vimeo": {
		width: 15,
		height: 13,
		viewBox: '0 0 15 13',
		data: `<path pid="0" d="M14.993 3.006c-.066 1.463-1.085 3.465-3.057 6.008C9.896 11.671 8.17 13 6.76 13c-.873 0-1.612-.809-2.217-2.426-.405-1.481-.808-2.964-1.21-4.447-.45-1.617-.932-2.426-1.446-2.426-.113 0-.505.237-1.178.709L0 3.495A518.6 518.6 0 0 0 2.196 1.54C3.177.686 3.916.236 4.41.19 5.578.074 6.296.874 6.564 2.588c.293 1.849.496 2.997.607 3.444.337 1.532.707 2.297 1.11 2.296.315 0 .787-.497 1.415-1.49.629-.994.965-1.75 1.01-2.269.09-.857-.247-1.286-1.01-1.288-.38.007-.754.091-1.101.247C9.33 1.11 10.739-.064 12.818.003c1.536.045 2.262 1.046 2.175 3.003z" _fill="#fff"/>`
	}
});
