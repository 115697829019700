/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"search": {
		width: 19,
		height: 19,
		viewBox: '0 0 19 19',
		data: `<path pid="0" d="M8.708 15.042a6.333 6.333 0 1 0 0-12.667 6.333 6.333 0 0 0 0 12.667zM16.625 16.625l-3.444-3.444" _stroke="#777A9E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>`
	}
});
