import { Store, set } from "idb-keyval";

export default async () => {
	const migrated = localStorage.getItem("migrated-highlights");
	if (migrated) return;

	const highlightStore = new Store("anichart-highlights", "highlights");
	const oldHighlightsKeys = Object.keys(localStorage).filter(key => key[0] === "i");
	await Promise.all(
		oldHighlightsKeys.map(async key => {
			const id = parseInt(key.replace("i", ""));
			const highlight = localStorage.getItem(key);
			await set(id, highlight, highlightStore);
		})
	);

	localStorage.setItem("migrated-highlights", true);
};
