import api from "@/api";
import CacheInvalidator from "@/util/cacheInvalidator";
import handleError from "@/util/errorHandler";

export default {
	async fetchSeason({ commit }, { season, year }) {
		try {
			const cacheInvalidator = new CacheInvalidator(`${year}-${season}`);
			const seasonGenerator = api.fetchBySeason({ season, year });
			let seasonData = await seasonGenerator.next();

			while (!seasonData.done) {
				commit("setEntities", seasonData.value.entities);
				commit("setChart", {
					entries: seasonData.value.chart,
					type: "season",
					season,
					year
				});
				cacheInvalidator.addNewCache(seasonData.value.chart);
				seasonData = await seasonGenerator.next();
			}

			cacheInvalidator.deleteInvalidCache();
		} catch (error) {
			handleError(error);
		}
	},
	async fetchTBA({ commit }) {
		try {
			const cacheInvalidator = new CacheInvalidator("tba");
			const chartGenerator = api.fetchTBA();
			let chartData = await chartGenerator.next();
			while (!chartData.done) {
				commit("setEntities", chartData.value.entities);
				commit("setChart", {
					entries: chartData.value.chart,
					type: "tba"
				});
				cacheInvalidator.addNewCache(chartData.value.chart);
				chartData = await chartGenerator.next();
			}

			cacheInvalidator.deleteInvalidCache();
		} catch (error) {
			handleError(error);
		}
	},
	async fetchAiring({ commit }) {
		try {
			const cacheInvalidator = new CacheInvalidator("airing");
			const airingGenerator = api.fetchAiring();
			let airingData = await airingGenerator.next();
			while (!airingData.done) {
				commit("setEntities", airingData.value.entities);
				commit("setChart", {
					entries: airingData.value.chart,
					type: "airing"
				});
				cacheInvalidator.addNewCache(airingData.value.chart);
				airingData = await airingGenerator.next();
			}

			cacheInvalidator.deleteInvalidCache();
		} catch (error) {
			handleError(error);
		}
	},
	async fetchUser({ commit, state }) {
		try {
			const token = state.auth.token;
			if (!token) return;

			const { data } = await api.fetchUser(token);
			commit("setUser", data);
			return true;
		} catch (error) {
			handleError(error);
			return false;
		}
	},
	async updateAuthSettings({ state }, settings) {
		try {
			const token = state.auth.token;
			if (!token) return;

			await api.updateSettings({
				settings,
				token
			});
		} catch (error) {
			handleError(error);
		}
	},
	async updateHighlights({ state }, highlights) {
		try {
			const token = state.auth.token;
			if (!token) return;

			await api.updateHighlights({
				highlights,
				token
			});
		} catch (error) {
			handleError(error);
		}
	},
	async updateListEntry({ state, dispatch }, variables) {
		try {
			const token = state.auth.token;
			if (!token) return;

			await api.updateListEntry({
				variables,
				token
			});

			const status = variables.status === "CURRENT" ? "watching" : "planning";
			dispatch("notify", {
				header: "List Updated",
				text: `Added to ${status} list`,
				type: "success"
			});
		} catch (error) {
			handleError(error);
		}
	},
	updateScreenSize({ commit }) {
		commit("setScreenSize", {
			width: window.innerWidth,
			height: window.innerHeight
		});
	},
	addBannerImage(store, { id, url }) {
		if (store.state.screen.isMobile) return;
		setTimeout(() => {
			const bannerImage = new Image();
			bannerImage.onload = () => {
				store.commit("addBannerImage", { id, url });
			};
			bannerImage.src = url;
		}, 3000);
	},
	notify({ commit }, notification) {
		if (typeof notification === "string") {
			notification = {
				text: notification
			};
		}
		commit("createNotification", {
			...notification,
			id: Math.random()
		});
	}
};
