/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Hidive": {
		width: 15,
		height: 15,
		viewBox: '0 0 15 15',
		data: `<path pid="0" d="M3.317 13.186H1s.028-8.128.028-9.067c0-.939.702-1.86 2.29-2.119v4.56h3.928V2h2.317v11.186H7.246v-4.56H3.317v4.56zM11.683 2H14v11.267h-2.317z" _fill="#fff"/>`
	}
});
