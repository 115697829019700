/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Hulu": {
		width: 15,
		height: 15,
		viewBox: '0 0 15 15',
		data: `<path pid="0" d="M8.99 4.677H6.73c-.802 0-1.193.21-1.193.21V0H2v14.979h3.516V8.954c0-.527.432-.97.946-.97h2.056c.514 0 .946.443.946.97V15H13V8.47c0-2.74-1.789-3.793-4.01-3.793z" _fill="#fff"/>`
	}
});
