/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"hash": {
		width: 15,
		height: 15,
		viewBox: '0 0 15 15',
		data: `<path pid="0" d="M2.5 5.625h10M2.5 9.375h10M6.25 1.875L5 13.125M10 1.875l-1.25 11.25" _stroke="#76868E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>`
	}
});
