/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"AnimeLab": {
		width: 15,
		height: 15,
		viewBox: '0 0 15 15',
		data: `<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.53 15L1 11.258V3.774L7.53 0l6.53 3.742v7.484L7.53 15zm0-9.826L4.494 9.826 7.53 8.72l3.037 1.106L7.53 5.174z" _fill="#fff"/>`
	}
});
