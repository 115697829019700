/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"user": {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data: `<path pid="0" d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/><circle pid="1" cx="12" cy="7" r="4"/>`
	}
});
