/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"check-circle": {
		width: 700,
		height: 700,
		viewBox: '0 0 700 700',
		data: `<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M350 700c193.3 0 350-156.7 350-350S543.3 0 350 0 0 156.7 0 350s156.7 350 350 350zm155.314-419.059l-184 184c-6.249 6.248-16.379 6.248-22.628-.001l-104-104c-6.248-6.248-6.248-16.379 0-22.627l22.627-22.627c6.249-6.248 16.38-6.248 22.628 0L310 385.745l150.059-150.058c6.249-6.249 16.38-6.249 22.628 0l22.627 22.627c6.248 6.248 6.248 16.379 0 22.627z" _fill="#000"/>`
	}
});
