/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"menu": {
		width: 30,
		height: 30,
		viewBox: '0 0 30 30',
		data: `<path pid="0" d="M4 15h22M4 5h22M4 25h22" _stroke="#EDF1F5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>`
	}
});
