/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Funimation": {
		width: 15,
		height: 15,
		viewBox: '0 0 15 15',
		data: `<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.5 15a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15zm0-2c4 0 4.5-3.5 4.5-3.5H3S3.5 13 7.5 13z" _fill="#fff"/>`
	}
});
