/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"logo": {
		width: 16,
		height: 16,
		viewBox: '0 0 408.8 308.5',
		data: `<path pid="0" d="M224.8 291.7c21 10.7 44.7 16.8 69.8 16.8 39.2 0 76.8-15 105.5-41.6 8.8-8.4 7.6-18-1-25.5l-30.6-25.8c-8.7-7-16.3-6-25.4.8-13.8 11-30.8 16.7-48.4 16.7-43.6 0-79.3-35.3-79.3-79 0-43.6 35.6-79 79.3-79.2 18.2 0 36 6.2 50 17.8 8.7 7 16.3 8.3 25 1.2L401 69.4c9.4-7.5 10.6-16.7 2.3-25C374.3 16 335.3 0 294.6 0 236 0 185 32.8 158.8 81.2l66 210.5z"/><path pid="1" d="M107.3 3L0 308.3h83.3l18.2-52.8h90.8l17.7 52.8h83L186 3h-78.7zm13.2 184.8l26-84.6 28.5 84.6h-54.5z"/>`
	}
});
