/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"VRV": {
		width: 15,
		height: 15,
		viewBox: '0 0 15 15',
		data: `<path pid="0" d="M7.5 0L1 3.75v7.5L7.5 15l6.5-3.75v-7.5L7.5 0zm4.244 9.862L7.5 12.302l-4.244-2.45V6.752L7.5 9.2l4.244-2.449v3.111z" _fill="#fff"/>`
	}
});
