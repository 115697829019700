import Vue from "vue";
import Vuex from "vuex";
import { getEpoch } from "@/util";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

export const initialStore = {
	strict: process.env.NODE_ENV !== "production",
	state: {
		charts: {},
		entities: {
			media: {},
			studio: {},
			airing: {},
			listEntry: {}
		},
		currentChart: null,
		sharedChart: false,
		imageChart: false,
		bannerImages: {},
		activeBannerImage: null,
		trailer: {
			active: false,
			trailer: {},
			position: {}
		},
		sort: "popularity",
		filter: null,
		airingView: "calendar",
		screen: {
			width: window.innerWidth,
			height: window.innerHeight,
			isMobile: window.innerWidth <= 800
		},
		auth: {
			token: null,
			user: {},
			settings: {},
			highlights: {}
		},
		notifications: [],
		time: getEpoch()
	},
	getters,
	mutations,
	actions
};

Vue.use(Vuex);
export default new Vuex.Store(initialStore);
