<template>
	<header class="nav">
		<div
			class="banner-image"
			v-for="(bannerImage, id) in bannerImages"
			:key="id"
			:class="{ active: id == activeBanner }"
			:style="`background-image: url('${bannerImage}')`"
			:id="id"
		/>

		<div class="wrap">
			<router-link to="/" exact class="logo-link" aria-label="AniChart">
				<icon name="logo" width="45" color="#41B1EA #FEFEFE" aria-label="AniChart" :fill="true" />
			</router-link>

			<div class="seasons">
				<template v-for="(year, season) in nextSeasons">
					<router-link
						class="season"
						:to="{ name: 'season', params: { season, year } }"
						:key="season"
					>
						<div class="season-name">{{ season }}</div>
						<div class="season-year">{{ year }}</div>
					</router-link>
				</template>
			</div>

			<div class="charts">
				<router-link to="/airing">
					<icon name="airing" width="18" aria-label="Airing" />
					<div class="label">Airing</div>
				</router-link>
				<router-link to="/archive">
					<icon name="archive" width="18" aria-label="Archive" />
					<div class="label">Archive</div>
				</router-link>
				<router-link to="/tba">
					<icon name="tba" width="18" aria-label="To be announced" />
					<div class="label">TBA</div>
				</router-link>
				<router-link to="/settings">
					<icon name="settings" width="18" stroke-width="2px" aria-label="Settings" />
					<div class="label">Settings</div>
				</router-link>
			</div>
		</div>
	</header>
</template>

<script>
import { nextSeasons } from "@/util";

export default {
	computed: {
		nextSeasons() {
			return nextSeasons();
		},
		bannerImages() {
			return this.$store.state.bannerImages;
		},
		activeBanner() {
			return this.$store.state.activeBannerImage;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.nav {
	background: #2b2d42;
	color: #fff;
	height: 233px;
	width: 100%;
	padding-top: 40px;
	position: relative;
}

.wrap {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	justify-items: center;
	margin: 0 12vw;
	position: relative;
	z-index: 30;

	@media (max-width: $size-desktop) {
		margin: 0 5vw;
	}
}

.logo-link {
	display: inline-block;
	font-size: 0;
}

.seasons {
	display: flex;
}

.season {
	color: hsl(236, 31%, 80%);
	text-align: center;
	width: 9rem;

	&.router-link-active {
		color: #fff;

		.season-year {
			color: hsla(235, 30%, 72%, 1);
		}
	}

	&:hover {
		color: hsl(236, 57%, 89%);
	}
}

.season-name {
	font-size: 1.6rem;
	font-weight: 600;
}

.season-year {
	color: hsl(235, 23%, 63%);
	font-size: 1rem;
	font-weight: 400;
}

.charts {
	display: grid;
	grid-gap: 12px;
	grid-template-columns: repeat(4, 1fr);
	text-align: center;

	.svg-icon {
		color: hsl(236, 31%, 80%);
		transition: color 0.3s;
	}

	.label {
		color: hsl(234, 29%, 69%);
		padding-top: 5px;
		font-size: 1.1rem;
		opacity: 0.9;
	}

	.router-link-active .svg-icon {
		color: #fff;
	}

	a:hover .svg-icon {
		color: hsl(236, 57%, 89%);
	}
}

.banner-image {
	backface-visibility: hidden;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	filter: grayscale(100%);
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	transition: opacity 500ms ease-in-out;
	width: 100%;
	z-index: 10;

	&.active {
		opacity: 0.1;
	}
}
</style>
