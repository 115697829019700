import { urlSlug } from "@/util";
import store from "../store";

class Settings {
	constructor() {
		this.defaults = {
			titleLanguage: "romaji",
			outgoingLinkProvider: "anilist",
			sort: "popularity",
			theme: "default",
			hideNotWatching: false,
			airingView: "calendar"
		};
	}

	get(key) {
		return localStorage.getItem(key) || this.defaults[key];
	}

	set(key, value) {
		const current = this[key];
		if (current !== value) {
			this.syncSetting(key, value);
			return localStorage.setItem(key, value);
		}
	}

	getAll() {
		const keys = Object.keys(this.defaults);
		const settings = {};
		keys.forEach(key => {
			settings[key] = this[key];
		});

		return settings;
	}

	setAll(settings) {
		const keys = Object.keys(settings);
		keys.forEach(key => {
			localStorage.setItem(key, settings[key]);
		});
	}

	getMediaTitle(media) {
		const language = this.titleLanguage;
		return media.title[language] || media.title["romaji"];
	}

	getMediaUrl(media) {
		if (this.outgoingLinkProvider === "mal" && media.idMal) {
			return `https://myanimelist.net/anime/${media.idMal}`;
		}

		return `https://anilist.co/anime/${media.id}/${urlSlug(media.title.romaji)}`;
	}

	async syncSetting(key, value) {
		const settings = {};
		settings[key] = value;
		store.dispatch("updateAuthSettings", settings);
	}

	get titleLanguage() {
		return this.get("titleLanguage");
	}

	set titleLanguage(language) {
		this.set("titleLanguage", language);
	}

	get outgoingLinkProvider() {
		return this.get("outgoingLinkProvider");
	}

	set outgoingLinkProvider(site) {
		this.set("outgoingLinkProvider", site);
	}

	get sort() {
		return this.get("sort");
	}

	set sort(sort) {
		this.set("sort", sort);
	}

	get theme() {
		return this.get("theme");
	}

	set theme(theme) {
		document.body.classList.remove("site-theme-default", "site-theme-dark", "site-theme-contrast");

		this.set("theme", theme);
		document.body.classList.add(`site-theme-${theme}`);
	}

	get hideNotWatching() {
		// LocalStorage stores booleans as strings
		return JSON.parse(this.get("hideNotWatching"));
	}

	set hideNotWatching(hidden) {
		this.set("hideNotWatching", hidden);
	}

	get airingView() {
		return this.get("airingView");
	}

	set airingView(view) {
		this.set("airingView", view);
	}
}

const settings = new Settings();
export default settings;
