<template>
	<div class="notifiction" :class="[placement, type, { visible }]">
		<div class="content">
			<div class="header" v-if="header">{{ header }}</div>
			<div class="text" v-if="text">{{ text }}</div>
		</div>
	</div>
</template>

<script>
const types = ["info", "success", "error"];
const placements = ["top", "bottom"];
const animationDutation = 500;

export default {
	name: "notification",
	props: {
		id: {
			required: true
		},
		header: {
			type: String,
			default: null
		},
		text: {
			type: String,
			default: null
		},
		type: {
			default: "info",
			validator: type => types.includes(type)
		},
		placement: {
			default: "top",
			validator: placement => placements.includes(placement)
		},
		duration: {
			type: Number,
			default: 8000
		}
	},
	data() {
		return {
			isVisible: false
		};
	},
	computed: {
		visible() {
			return this.isVisible;
		}
	},
	methods: {
		show() {
			this.isVisible = true;
		},
		hide() {
			this.isVisible = false;
			setTimeout(() => {
				this.$store.commit("removeNotification", this.id);
			}, this.duration);
		}
	},
	mounted() {
		setTimeout(this.show, 30);
		setTimeout(this.hide, this.duration + animationDutation);
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.notifiction {
	align-items: center;
	background: hsl(204, 45%, 98%);
	border-radius: 4px;
	border: solid 3px hsl(201, 100%, 78%);
	box-shadow: 0 4px 6px shadow(shadow, 0.05), 0 5px 20px shadow(shadow, 0.08);
	color: #103d55;
	display: flex;
	font-size: 1.7rem;
	font-weight: 700;
	margin-top: 30px;
	max-width: 550px;
	padding: 18px 24px;
	padding-right: 50px;
	position: fixed;
	right: -600px;
	transition: right 500ms ease-in-out;
	user-select: none;
	z-index: 900;

	.header + .text {
		margin-top: 3px;
	}

	.text {
		color: color(blue);
		font-size: 1.6rem;
		white-space: pre-line;
	}

	@media (max-width: $size-tablet) {
		padding: 14px 20px;
		font-size: 1.4rem;

		.header + .text {
			margin-top: 5px;
		}

		.text {
			font-size: 1.3rem;
		}
	}
}

.success {
	border: solid 3px color(green);

	.text {
		color: color(green);
	}
}

.error {
	border: solid 3px color(red);

	.text {
		color: color(red);
	}
}

.top {
	top: 30px;

	&.visible {
		right: 30px;
	}
}

.bottom {
	bottom: 100px;

	&.visible {
		right: 60px;
	}
}
</style>
