/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Viz": {
		width: 15,
		height: 15,
		viewBox: '0 0 15 15',
		data: `<path pid="0" d="M10.908 13.943a11.378 11.378 0 0 1-5.74-2.413 13.22 13.22 0 0 1-1.7-1.706A11.081 11.081 0 0 1 1.207 5C1.052 4.231 1 3.665 1 2.784V2h3.863l.02.75c.023.833.078 1.257.25 1.922.536 2.088 1.987 3.958 3.76 4.85l.268.134V2H13v12l-.839-.004a14.039 14.039 0 0 1-1.253-.053z" _fill="#fff"/>`
	}
});
