<template>
	<div class="not-found-view">
		<h2>404</h2>
		<h1>Page Not Found</h1>
	</div>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: "Page not found - 404"
		};
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.not-found-view {
	margin: 0 auto;
	max-width: 640px;
	padding: 0 20px;

	h1 {
		font-size: 5em;
		font-weight: 900;
		margin: 0;
	}

	h2 {
		margin-bottom: 15px;
		margin-top: 55px;

		@media (min-width: $size-tablet) {
			color: color(white);
		}
	}
}
</style>
