/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"play-circle": {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data: `<path pid="0" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" _stroke="#EDF1F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M10 8l6 4-6 4V8z" _stroke="#EDF1F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>`
	}
});
