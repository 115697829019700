/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"question-circle": {
		width: 700,
		height: 700,
		viewBox: '0 0 700 700',
		data: `<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M700 350c0 193.3-156.7 350-350 350S0 543.3 0 350 156.7 0 350 0s350 156.7 350 350zM345.408 175c-56.255 0-92.135 23.698-120.309 65.815-3.65 5.456-2.429 12.815 2.803 16.782l35.818 27.159c5.373 4.074 13.028 3.105 17.202-2.191 18.441-23.388 31.085-36.951 59.152-36.951 21.088 0 47.172 13.572 47.172 34.021 0 15.459-12.762 23.398-33.583 35.072l-1.752.981c-24.047 13.455-54.663 30.585-54.663 71.957v4.129c0 6.841 5.546 12.387 12.387 12.387h57.806c6.841 0 12.387-5.546 12.387-12.387v-1.376c0-11.108 12.274-18.191 27.541-27.001 25.115-14.493 58.329-33.66 58.329-83.107 0-59.873-62.106-105.29-120.29-105.29zm-54.354 303.484c0-26.183 21.301-47.484 47.484-47.484 26.183 0 47.484 21.301 47.484 47.484 0 26.182-21.301 47.484-47.484 47.484-26.183 0-47.484-21.302-47.484-47.484z" _fill="#000"/>`
	}
});
