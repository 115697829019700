/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Netflix": {
		width: 15,
		height: 15,
		viewBox: '0 0 15 15',
		data: `<path pid="0" d="M3.369.003l2.969 8.412v-.004l.234.662c1.306 3.693 2.007 5.672 2.01 5.676.003 0 .2.013.44.025.722.032 1.618.113 2.297.194.156.019.291.025.3.019l-2.944-8.35-.273-.769-1.514-4.281C6.601.774 6.357.082 6.344.056L6.325 0H3.372l-.003.003z" _fill="#fff"/><path pid="1" d="M8.686.008L8.68 3.324 8.675 6.64l-.273-.77v-.001l-.353 7.381c.347.98.532 1.502.534 1.504.003.003.2.015.439.027.723.03 1.619.112 2.3.193.156.02.292.025.3.02.008-.007.012-3.388.01-7.507l-.005-7.48h-2.94zM3.37 0v7.494c0 4.121.004 7.497.01 7.502.004.005.26-.019.566-.054.306-.037.731-.081.944-.1a38.216 38.216 0 0 1 1.405-.094c.032 0 .035-.168.04-3.175L6.337 8.4l.238.663.08.235.357-7.375-.119-.341-.55-1.525L6.326 0H3.369z" _fill="#fff"/>`
	}
});
