/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Wakanim": {
		width: 15,
		height: 15,
		viewBox: '0 0 15 15',
		data: `<path pid="0" d="M2.976 1.34A7.477 7.477 0 0 0 .229 5.487c-.646 2.586.108 5.224 1.831 7.002 0 0 .324.323.377.27l-.053-.162c-1.347-2.1-.647-4.309-.27-5.17.054-.162.108-.108.108-.108.054 0 .108.215.108.215.323 3.124 1.4 5.17 2.046 6.14 0 0 .162.269.27.161 0 0 .053-.054.053-.215-.053-.916.162-2.909 1.94-6.57C8.307 3.601 9.061 1.716 9.33.801c0 0 .108-.27 0-.323 0 0-.108-.054-.27.161-.915 1.024-2.907 3.286-3.823 4.578 0 0-.108.108-.162.108 0 0-.053 0-.053-.216.053-1.023.43-3.662 1.777-4.847 0 0 .161-.107.108-.215 0 0-.054-.108-.377 0C5.238.533 3.03 1.664 1.737 4.464c0 0-.054.162-.107.162 0 0-.054 0 0-.27.107-.7.43-1.992 1.508-3.016 0 0 .215-.215.161-.215.054 0 0 0-.323.215zM12.024 13.297c1.346-.97 2.316-2.424 2.747-4.147.646-2.585-.108-5.224-1.831-7.002 0 0-.324-.323-.377-.269l.053.162c1.347 2.1.647 4.308.27 5.17-.054.162-.108.108-.108.108-.054 0-.108-.216-.108-.216-.323-3.123-1.4-5.17-2.046-6.14 0 0-.162-.269-.27-.161 0 0-.053.054-.053.215.053.916-.162 2.909-1.94 6.571-1.669 3.447-2.423 5.332-2.692 6.247 0 0-.108.27 0 .323 0 0 .108.054.27-.161.915-1.023 2.907-3.285 3.823-4.578 0 0 .108-.108.162-.108 0 0 .053 0 .053.216-.053 1.023-.43 3.662-1.777 4.847 0 0-.161.108-.108.215 0 0 .054.108.324 0 1.292-.484 3.5-1.615 4.793-4.416 0 0 .054-.162.108-.162 0 0 .053 0 0 .27-.108.7-.431 1.992-1.508 3.016 0 0-.216.215-.162.215.054 0 .054 0 .377-.215z" _fill="#fff"/>`
	}
});
