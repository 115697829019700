<template>
	<div id="app" ref="app" :class="{ 'image-view': isImage }">
		<mobile-nav v-if="isMobile" />
		<desktop-nav v-else />
		<div class="image-nav" v-if="isImage">{{ currentChart }}</div>
		<div class="main-content"><router-view /></div>

		<template v-for="notification in notifications">
			<notification v-bind="notification" :key="notification.id" />
		</template>
		<trailer-modal
			v-if="trailerModal.active"
			:trailer="trailerModal.trailer"
			:position="trailerModal.position"
		/>
		<div class="image-time" v-if="isImage">
			{{ imageTime }}<br />
			AniChart.net
		</div>
	</div>
</template>

<script>
import { mapMutations } from "vuex";
import DesktopNav from "@/components/DesktopNav";
import MobileNav from "@/components/MobileNav";
import Notification from "@/components/Notification";
import TrailerModal from "@/components/TrailerModal";
import Settings from "@/util/settings";
import Auth from "@/util/auth";
import migrateHighlights from "@/util/migrateHighlights";
import "@/components/icons";

export default {
	components: {
		DesktopNav,
		MobileNav,
		Notification,
		TrailerModal
	},
	computed: {
		isMobile() {
			return this.$store.state.screen.isMobile;
		},
		notifications() {
			return this.$store.state.notifications;
		},
		trailerModal() {
			return this.$store.state.trailer || {};
		},
		isImage() {
			return this.$store.state.imageChart;
		},
		currentChart() {
			const [year, season] = this.$store.state.currentChart.split("-");
			return `${season} ${year}`;
		},
		imageTime() {
			const date = new Date().toLocaleString();
			return `Image generated on ${date}`;
		}
	},
	methods: {
		...mapMutations(["updateCounter"]),
		applySiteTheme() {
			document.body.classList.add(`site-theme-${Settings.theme}`);
		},
		applySavedSettings() {
			const { sort, airingView } = Settings;
			if (sort) {
				this.$store.commit("setSort", sort);
			}
			if (airingView) {
				this.$store.commit("setAiringView", airingView);
			}
		},
		watchScreenSize() {
			window.addEventListener("resize", () => {
				this.$store.dispatch("updateScreenSize");
			});
		},
		async login() {
			const authenticated = await Auth.init();
			if (authenticated) {
				await Auth.syncClient();
			}
		}
	},
	async created() {
		await migrateHighlights();
		this.watchScreenSize();
		await this.login();
		this.applySiteTheme();
		this.applySavedSettings();
		setInterval(() => {
			this.updateCounter();
		}, 30000);
	}
};
</script>

<style lang="scss">
@import "~@/styles/global.scss";
@import "~@/styles/variables.scss";

.main-content {
	margin-top: -110px;
	position: relative;
	z-index: 60;
	margin-bottom: 300px;

	@media (max-width: $size-tablet) {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.image-view {
	.main-content {
		margin-bottom: 30px;
		margin-top: 0;

		.card-list,
		.section-heading {
			grid-column-gap: 40px;
			grid-row-gap: 40px;
			grid-template-columns: repeat(3, 500px);
		}

		.card-list {
			grid-template-rows: repeat(auto-fill, 300px);
		}

		.media-card {
			grid-template-columns: 205px auto;
			height: 300px;
			min-width: 370px;

			.description-wrap {
				color: color(text);
				font-size: 1.2rem;
			}
		}
		
		.sense-wrap {
			display: none;
		}
	}

	.filters,
	.nav .charts,
	.nav .seasons,
	.highlighter .icon:not(.active) {
		display: none;
	}
}

.image-nav {
	color: #fff;
	font-size: 3rem;
	font-weight: 500;
	margin-bottom: 30px;
	margin-top: -200px;
	padding-top: 4px;
	position: relative;
	text-align: center;
	width: 100%;
}

.image-time {
	font-weight: 700;
	text-align: center;
	margin-bottom: 20px;
}
</style>
