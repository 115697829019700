import store from "@/store";

export default async function captureError(error) {
	if (error && error.name && error.name == "user") {
		store.dispatch("notify", {
			header: "Error",
			text: error.message,
			type: "error"
		});
	}
}

window.onerror = (message, url, line, column, error) => captureError(error);
window.onunhandledrejection = event => captureError(event.reason);
