/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"share": {
		width: 19,
		height: 19,
		viewBox: '0 0 19 19',
		data: `<path pid="0" d="M14.25 6.333a2.375 2.375 0 1 0 0-4.75 2.375 2.375 0 0 0 0 4.75zM4.75 11.875a2.375 2.375 0 1 0 0-4.75 2.375 2.375 0 0 0 0 4.75zM14.25 17.417a2.375 2.375 0 1 0 0-4.75 2.375 2.375 0 0 0 0 4.75zM6.8 10.695l5.407 3.151M12.2 5.154l-5.4 3.15" _stroke="#777A9E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>`
	}
});
