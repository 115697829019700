export const capitalize = str => {
	if (!str) return null;

	const blacklist = ["OVA", "ONA", "TV", "TV Short"];
	if (blacklist.includes(str)) return str;

	const word = str.replace(/_/g, " ");

	if (word.includes(" ")) {
		const words = word.split(" ");
		return words.map(w => capitalize(w)).join(" ");
	}

	const firstLetter = word.charAt(0).toUpperCase();
	const remainingString = word.slice(1, word.length).toLowerCase();
	return firstLetter + remainingString;
};

export const pluralize = (value, label) => {
	if (value === 1) {
		return `${value} ${label}`;
	}
	return `${value} ${label}s`;
};

export const urlSlug = str => {
	if (str === null || !str) {
		return null;
	}

	return encodeURIComponent(
		str
			.replace(/[ ]/g, "_")
			.replace(/[\W]/g, "")
			.replace(/[_]/g, "-")
	);
};

export const sleep = ms => {
	return new Promise(resolve => setTimeout(resolve, ms));
};

export const getEpoch = () => Math.trunc(new Date().getTime() / 1000);

export const getSeason = (offsetMonths = 0, date = new Date()) => {
	if (typeof date.getMonth !== "function") throw new Error("Invalid Date Object");
	else if (offsetMonths > 12 || offsetMonths < -12)
		throw new Error("getSeason does not support offset months greater than 12");

	let year = date.getFullYear();
	let month = date.getMonth() + offsetMonths;
	if (month >= 12) {
		month = month % 12;
		year = year + 1;
	} else if (month < 0) {
		month = month + 12;
		year = year - 1;
	}

	const seasons = [
		{ season: "winter", range: [0, 2] },
		{ season: "spring", range: [3, 5] },
		{ season: "summer", range: [6, 8] },
		{ season: "fall", range: [9, 11] }
	];
	const { season } = seasons.find(({ range }) => month >= range[0] && month <= range[1]);

	return {
		season,
		year
	};
};

// Generate object with the seasons and years for the navbar
export const nextSeasons = () => {
	const seasons = ["Winter", "Spring", "Summer", "Fall"];
	const current = getSeason();
	const previous = getSeason(-3);

	let thisYear = false;
	const seasonList = {};
	seasons.forEach(season => {
		if (current.season === season.toLowerCase()) {
			thisYear = true;
		}

		if (previous.season === season.toLowerCase()) {
			seasonList[season] = previous.year;
		} else if (thisYear) {
			seasonList[season] = current.year;
		} else {
			seasonList[season] = current.year + 1;
		}
	});

	return seasonList;
};

// One-based index
export const getMonth = (month, short = false) => {
	const months = [
		{ full: "January", short: "Jan" },
		{ full: "February", short: "Feb" },
		{ full: "March", short: "Mar" },
		{ full: "April", short: "Apr" },
		{ full: "May", short: "May" },
		{ full: "June", short: "Jun" },
		{ full: "July", short: "Jul" },
		{ full: "August", short: "Aug" },
		{ full: "September", short: "Sep" },
		{ full: "October", short: "Oct" },
		{ full: "November", short: "Nov" },
		{ full: "December", short: "Dec" }
	];

	return months[month - 1][short ? "short" : "full"];
};

export const getAiringDays = () => {
	const today = new Date().getDay();
	const order = [];
	for (let i = 0; i < 7; i++) {
		const day = today + i;
		order.push(day > 6 ? day - 7 : day);
	}
	order.unshift(order.pop());

	return order;
};
