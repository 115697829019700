import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import * as filters from "./util/filters";
import enableCache from "./store/cache";
import { capitalize } from "./util";

// Plugins
import * as svgicon from "vue-svgicon";
import Ads from "vue-google-adsense";
import VS2 from "vue-script2";
import Meta from "vue-meta";
import VueAnalytics from "vue-analytics";
// import "./registerServiceWorker";
import { Store as idbStore } from "idb-keyval";
import Vuebar from "vuebar";

Vue.config.productionTip = false;

// Register global utility filters
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key]);
});

Vue.use(Meta);
Vue.use(Vuebar);
Vue.use(svgicon, {
	tagName: "icon",
	isStroke: true
});

// Google
Vue.use(VS2);
Vue.use(Ads.Adsense);
Vue.use(VueAnalytics, {
	id: "UA-40137036-1",
	router,
	debug: {
		sendHitTask: process.env.NODE_ENV === "production"
	}
});

if (process.env.NODE_ENV === "production") {
	enableCache();
}

const app = new Vue({
	router,
	store,
	render: h => h(App),
	metaInfo: {
		titleTemplate: chunk => {
			if (chunk.startsWith("AniChart:")) return chunk;
			return `AniChart: ${capitalize(chunk)}`;
		}
	}
}).$mount("#app");

// Create idb store
// Currently each store requires its own db: https://github.com/jakearchibald/idb-keyval/issues/31
const highlightStore = new idbStore("anichart-highlights", "highlights");

try {
	// Unregister any running service workers
	if ("serviceWorker" in navigator) {
		navigator.serviceWorker.getRegistrations().then(registrations => {
			for (let registration of registrations) {
				registration.unregister();
			}
		});
	}
} catch (e) {}

export { app, highlightStore };
