import { Store, set, get, del, keys } from "idb-keyval";
import _ from "lodash";
import vuexStore from ".";

const VERSION = 4;
const store = new Store("anichart", "vuex-cache");

async function clearOldCaches() {
	const cacheKeys = await keys(store);
	const possibleKeys = [...Array(VERSION).keys()];

	possibleKeys.forEach(key => {
		if (cacheKeys.includes(key)) {
			del(key, store);
			console.info(`Stale cache removed: v${key}`);
		}
	});
}

async function restoreCache() {
	const cache = await get(VERSION, store);
	if (!cache) return;
	vuexStore.commit("restoreCache", cache);
}

const updateCache = _.debounce((mutation, state) => {
	const allowMutations = ["setEntities", "setChart", "removeInvalidChartEntries"];
	if (allowMutations.includes(mutation.type)) {
		set(VERSION, state, store);
	}
}, 3000);

async function enableCache() {
	try {
		await restoreCache();
		await clearOldCaches();
		vuexStore.subscribe(updateCache);

		console.info(`Cache enabled: v${VERSION}`);
	} catch (error) {
		console.error("Failed enable cache");
		console.error(error);
	}
}

export default enableCache;
