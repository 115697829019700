/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"cross-circle": {
		width: 700,
		height: 700,
		viewBox: '0 0 700 700',
		data: `<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M350 700c193.3 0 350-156.7 350-350S543.3 0 350 0 0 156.7 0 350s156.7 350 350 350zm121.725-267.675c4.7-4.7 4.7-12.3 0-17l-65.6-65.1 65.7-65c4.7-4.7 4.7-12.3 0-17l-39.6-39.6c-4.7-4.7-12.3-4.7-17 0l-65.1 65.6-65-65.7c-4.7-4.7-12.3-4.7-17 0l-39.6 39.6c-4.7 4.7-4.7 12.3 0 17l65.6 65.1-65.6 65c-4.7 4.7-4.7 12.3 0 17l39.5 39.6c4.7 4.7 12.3 4.7 17 0l65.1-65.6 65 65.6c4.7 4.7 12.3 4.7 17 0l39.6-39.5z" _fill="#000"/>`
	}
});
