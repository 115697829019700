/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"plus-circle": {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data: `<circle pid="0" cx="12" cy="12" r="10"/><path pid="1" d="M12 8v8M8 12h8"/>`
	}
});
