/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"tba": {
		width: 17,
		height: 15,
		viewBox: '0 0 17 15',
		data: `<path pid="0" d="M9.625 13.125L15.25 7.5 9.625 1.875M1.75 13.125L7.375 7.5 1.75 1.875" _stroke="#E1E1E4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>`
	}
});
