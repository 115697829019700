/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Amazon": {
		width: 15,
		height: 15,
		viewBox: '0 0 15 15',
		data: `<path pid="0" d="M13.878 11.698c-.42-.573-.87-1.04-.87-2.107V6.047c0-1.502.11-2.88-1.01-3.914C11.116 1.296 9.652 1 8.532 1c-2.19 0-4.633.807-5.146 3.483-.054.284.155.433.344.475l2.23.238c.21-.01.36-.213.4-.417.192-.922.973-1.365 1.85-1.365.474 0 1.011.17 1.291.59.323.468.28 1.11.28 1.65v.296c-1.334.148-3.079.245-4.328.787C4.012 7.354 3 8.609 3 10.454 3 12.818 4.506 14 6.444 14c1.636 0 2.531-.382 3.793-1.653.42.6.556.888 1.32 1.516a.48.48 0 0 0 .543-.054l.006.005c.46-.404 1.294-1.12 1.764-1.51.188-.153.155-.4.008-.606zM9.35 10.677c-.365.641-.947 1.034-1.593 1.034-.882 0-1.4-.666-1.4-1.65 0-1.937 1.76-2.29 3.423-2.29v.492c0 .886.022 1.624-.43 2.414z" _fill="#fff"/>`
	}
});
