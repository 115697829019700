/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Twitter": {
		width: 15,
		height: 12,
		viewBox: '0 0 15 12',
		data: `<path pid="0" d="M4.717 12c5.66 0 8.757-4.617 8.757-8.621 0-.131 0-.262-.01-.392A6.209 6.209 0 0 0 15 1.42a6.218 6.218 0 0 1-1.768.477A3.05 3.05 0 0 0 14.585.22a6.226 6.226 0 0 1-1.954.735A3.096 3.096 0 0 0 10.885.04a3.122 3.122 0 0 0-1.951.319A3.053 3.053 0 0 0 7.582 1.78a2.99 2.99 0 0 0-.195 1.939 8.845 8.845 0 0 1-3.514-.92A8.713 8.713 0 0 1 1.044.554 2.992 2.992 0 0 0 .707 2.76a3.032 3.032 0 0 0 1.29 1.837A3.091 3.091 0 0 1 .6 4.218v.039c0 .7.246 1.377.696 1.919.45.54 1.077.912 1.773 1.05a3.12 3.12 0 0 1-1.39.053c.197.602.58 1.128 1.095 1.506a3.113 3.113 0 0 0 1.78.598A6.238 6.238 0 0 1 0 10.64a8.813 8.813 0 0 0 4.717 1.359" _fill="#fff"/>`
	}
});
