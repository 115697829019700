/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Youtube": {
		width: 15,
		height: 15,
		viewBox: '0 0 15 15',
		data: `<path pid="0" d="M4 2.867v9.309a.5.5 0 0 0 .731.443l8.462-4.41a.5.5 0 0 0 .02-.875l-8.462-4.9A.5.5 0 0 0 4 2.868z" _fill="#fff"/>`
	}
});
