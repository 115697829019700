/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"compass": {
		width: 16,
		height: 16,
		viewBox: '0 0 16 16',
		data: `<path pid="0" d="M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334z" _stroke="#76868E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M10.827 5.173l-1.414 4.24-4.24 1.414 1.414-4.24 4.24-1.414z" _stroke="#76868E" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>`
	}
});
