/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"screenshot": {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data: `<path pid="0" d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2v11z" _stroke="#777A9E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M12 17a4 4 0 1 0 0-8 4 4 0 0 0 0 8z" _stroke="#777A9E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>`
	}
});
