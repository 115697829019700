/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Crunchyroll": {
		width: 15,
		height: 15,
		viewBox: '0 0 15 15',
		data: `<path pid="0" d="M1.808 8.404a6.591 6.591 0 0 1 6.596-6.596 6.6 6.6 0 0 1 6.58 6.136C15 7.801 15 7.643 15 7.5 15 3.362 11.639 0 7.5 0A7.505 7.505 0 0 0 0 7.5C0 11.639 3.362 15 7.5 15c.174 0 .349 0 .507-.016a6.599 6.599 0 0 1-6.2-6.58z" _fill="#fff"/><path pid="1" d="M12.05 8.768a2.392 2.392 0 0 1-.808-4.645 5.314 5.314 0 0 0-2.458-.603 5.278 5.278 0 0 0-5.28 5.28 5.278 5.278 0 0 0 5.28 5.28 5.278 5.278 0 0 0 5.28-5.28c0-.333-.031-.65-.095-.967a2.405 2.405 0 0 1-1.918.935z" _fill="#fff"/>`
	}
});
