import Vue from "vue";
import _ from "lodash";
import { getEpoch } from "@/util";
import Settings from "@/util/settings";

export default {
	setEntities(state, entities) {
		mergeEntities(state, entities);
	},
	setChart: (state, { entries = [], year, season, type }) => {
		const key = type === "season" ? `${year}-${season.toLowerCase()}` : type;
		const currentEntries = state.charts[key] || [];
		Vue.set(state.charts, key, _.union(currentEntries, entries));
	},
	setCurrentChart: (state, chartKey) => {
		state.currentChart = chartKey;
	},
	updateCounter: state => {
		state.time = getEpoch();
	},
	addBannerImage: (state, { id, url }) => {
		Vue.set(state.bannerImages, id, url);
	},
	setActiveBannerImage: (state, id = null) => {
		state.activeBannerImage = id;
	},
	setTrailer: (state, { active = true, trailer = {}, position = {} }) => {
		state.trailer = {
			active,
			trailer,
			position
		};
	},
	setSort(state, sort) {
		state.sort = sort;
		Settings.sort = sort;
	},
	setAiringView(state, view) {
		state.airingView = view;
		Settings.airingView = view;
	},
	setFilter(state, filter) {
		state.filter = filter;
	},
	setScreenSize(state, sizes) {
		state.screen = {
			...sizes,
			isMobile: window.innerWidth <= 800
		};
	},
	setSharedChart(state, isShared) {
		state.sharedChart = isShared;
	},
	setImageChart(state, isImage) {
		state.imageChart = isImage;
	},
	setAccessToken(state, token) {
		state.auth.token = token;
	},
	setUser(state, { user, settings, highlights }) {
		state.auth.user = user;
		state.auth.settings = settings;
		state.auth.highlights = highlights;
	},
	restoreCache(state, cache) {
		mergeEntities(state, cache.entities);

		for (const id in cache.charts) {
			if (!state.charts[id]) {
				Vue.set(state.charts, id, cache.charts[id]);
			}
		}
	},
	createNotification(state, notification) {
		state.notifications.push(notification);
	},
	removeNotification(state, id) {
		state.notifications = state.notifications.filter(n => n.id !== id);
	},
	removeInvalidChartEntries(state, { chartKey, entries }) {
		chartKey = chartKey.toLowerCase();
		if (!state.charts[chartKey]) return;
		state.charts[chartKey] = state.charts[chartKey].filter(id => !entries.includes(id));
	}
};

function mergeEntities(state, entities) {
	const merger = (currentValue, newValue) => (Array.isArray(newValue) ? newValue : undefined);

	for (const type in entities) {
		const typeEntities = state.entities[type] || {};

		for (const id in entities[type]) {
			const source = typeEntities[id];

			if (source) {
				typeEntities[id] = _.mergeWith(source, entities[type][id], merger);
			} else {
				typeEntities[id] = entities[type][id];
			}
		}

		Vue.set(state.entities, type, typeEntities);
	}
}
