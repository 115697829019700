/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Summer": {
		width: 22,
		height: 22,
		viewBox: '0 0 22 22',
		data: `<path pid="0" d="M11.81.543l1.096 2.681a.773.773 0 0 0 1.122.365l2.463-1.524c.666-.412 1.496.19 1.31.951l-.689 2.814a.773.773 0 0 0 .694.955l2.889.214c.78.058 1.097 1.034.5 1.54l-2.211 1.87a.773.773 0 0 0 0 1.181l2.21 1.871c.598.506.281 1.482-.5 1.54l-2.888.214a.774.774 0 0 0-.694.956l.689 2.813c.186.76-.644 1.363-1.31.951l-2.462-1.524a.773.773 0 0 0-1.123.365l-1.097 2.68c-.296.725-1.322.725-1.618 0l-1.097-2.68a.773.773 0 0 0-1.123-.365l-2.463 1.524c-.665.412-1.495-.19-1.31-.951l.69-2.814a.773.773 0 0 0-.694-.955l-2.889-.214c-.78-.058-1.098-1.034-.5-1.54l2.21-1.87a.774.774 0 0 0 0-1.181L.806 8.538c-.598-.505-.28-1.481.5-1.539l2.889-.214a.773.773 0 0 0 .694-.955l-.69-2.814c-.185-.76.645-1.363 1.31-.951l2.463 1.524a.773.773 0 0 0 1.123-.365l1.097-2.68c.296-.725 1.322-.725 1.618 0zM16.843 11A5.85 5.85 0 0 0 11 5.156 5.85 5.85 0 0 0 5.156 11 5.85 5.85 0 0 0 11 16.844 5.85 5.85 0 0 0 16.844 11zm-1.375 0a4.474 4.474 0 0 1-4.47 4.469A4.474 4.474 0 0 1 6.532 11 4.474 4.474 0 0 1 11 6.531 4.474 4.474 0 0 1 15.469 11z" _fill="#fff" fill-opacity=".85"/>`
	}
});
