/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"airing": {
		width: 18,
		height: 18,
		viewBox: '0 0 18 18',
		data: `<path pid="0" d="M14.25 3H3.75a1.5 1.5 0 0 0-1.5 1.5V15a1.5 1.5 0 0 0 1.5 1.5h10.5a1.5 1.5 0 0 0 1.5-1.5V4.5a1.5 1.5 0 0 0-1.5-1.5zM12 1.5v3M6 1.5v3M2.25 7.5h13.5" _stroke="#EDF1F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>`
	}
});
