/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Winter": {
		width: 20,
		height: 22,
		viewBox: '0 0 20 22',
		data: `<path pid="0" d="M19.858 12.961c.131-.22.174-.48.119-.73-.122-.557-.685-.912-1.258-.794l-3.902 1.076L11.786 11l3.03-1.513 3.903 1.076c.573.118 1.136-.237 1.258-.795a1.032 1.032 0 0 0-.817-1.222l-2.12-.307 2.37-1.33c.507-.285.68-.916.388-1.409a1.077 1.077 0 0 0-1.45-.377l-2.369 1.33.787-1.938a1.026 1.026 0 0 0-.68-1.3 1.067 1.067 0 0 0-1.337.662L13.756 7.7l-2.695 1.513V6.187l2.91-2.747a1.011 1.011 0 0 0-.08-1.457 1.082 1.082 0 0 0-1.497.077L11.06 3.692v-2.66C11.06.461 10.586 0 10 0c-.587 0-1.062.462-1.062 1.031v2.66l-1.332-1.63a1.082 1.082 0 0 0-1.498-.078 1.011 1.011 0 0 0-.08 1.457l2.91 2.748v3.026L6.244 7.7 5.25 3.877a1.067 1.067 0 0 0-1.336-.662 1.026 1.026 0 0 0-.68 1.3l.786 1.938-2.37-1.33A1.077 1.077 0 0 0 .202 5.5 1.016 1.016 0 0 0 .591 6.91l2.369 1.33-2.12.307a1.032 1.032 0 0 0-.817 1.223c.122.557.685.912 1.258.794l3.902-1.076L8.214 11l-3.03 1.513-3.903-1.076c-.573-.118-1.136.237-1.258.795a1.032 1.032 0 0 0 .818 1.223l2.12.306-2.37 1.33A1.016 1.016 0 0 0 .202 16.5c.293.493.942.662 1.45.378l2.369-1.33-.787 1.937a1.001 1.001 0 0 0 .09.835c.127.213.331.383.59.465a1.067 1.067 0 0 0 1.337-.662l.993-3.824 2.695-1.513v3.027L6.03 18.56a1.011 1.011 0 0 0 .08 1.457 1.082 1.082 0 0 0 1.497-.077l1.333-1.632v2.66C8.94 21.539 9.414 22 10 22s1.06-.462 1.06-1.031v-2.66l1.333 1.63c.1.108.22.194.357.253a1.089 1.089 0 0 0 1.141-.175c.436-.381.471-1.034.08-1.457l-2.91-2.747v-3.027l2.695 1.513.993 3.824c.18.542.78.838 1.336.662.557-.176.862-.758.68-1.3l-.786-1.938 2.37 1.33a1.077 1.077 0 0 0 1.449-.377 1.016 1.016 0 0 0-.389-1.409l-2.37-1.33 2.12-.307c.307-.063.554-.248.699-.493z" _fill="#fff" fill-opacity=".85"/>`
	}
});
