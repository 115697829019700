/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"archive": {
		width: 18,
		height: 18,
		viewBox: '0 0 18 18',
		data: `<g clip-path="url(#clip0)" _stroke="#EDF1F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M15.75 6v8.75a1 1 0 0 1-1 1H3.25a1 1 0 0 1-1-1V6M16.25 2.25H1.75a1 1 0 0 0-1 1V5a1 1 0 0 0 1 1h14.5a1 1 0 0 0 1-1V3.25a1 1 0 0 0-1-1zM7.5 9h3"/></g><defs><clipPath id="clip0"><path pid="1" _fill="#fff" d="M0 0h18v18H0z"/></clipPath></defs>`
	}
});
