/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"grid": {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data: `<path pid="0" d="M8 3H5a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zM19 3h-3a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zM19 14h-3a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2zM8 14H5a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2z" _stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>`
	}
});
