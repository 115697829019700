/* eslint-disable */
import icon from "vue-svgicon";

icon.register({
	"Viewster": {
		width: 15,
		height: 15,
		viewBox: '0 0 15 15',
		data: `<g clip-path="url(#clip0)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.57 8.927c-2.351 1.339-2.813 1.16-2.813-1.437-.001-2.521.377-2.758 2.683-1.475 2.164 1.203 2.366 1.639.13 2.912zm6.102-3.115c2.781-1.583 3.327-1.327 3.328 1.7 0 3.027-.446 3.262-3.174 1.745-2.56-1.423-2.8-1.938-.154-3.445zM4.645 12c4.228.01 4.688-.298 4.726-4.386C9.409 3.471 8.995 2.99 4.645 3c-4.272.01-4.68.454-4.643 4.614.038 4 .407 4.375 4.643 4.386z" _fill="#fff"/></g><defs><clipPath id="clip0"><path pid="1" _fill="#fff" d="M0 0h15v15H0z"/></clipPath></defs>`
	}
});
